
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
            <v-layout row wrap px-4 mb-4>
                <v-flex xs9>
                    <v-breadcrumbs class="px-0" :items="$store.getters.language.data.items.edit_path">
                        <template v-slot:item="{item}">
                            <v-breadcrumbs-item :to="item.path" :disabled="item.disabled" exact>
                                {{ item.title }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-flex>
                <v-flex xs3></v-flex>
            </v-layout>
            <form @submit.prevent="updateItems" autocomplete="off">
                <v-layout row wrap>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="categories" v-model="items.category_id" dense  filled outlined item-text="category_name"
                                    item-value="category_id" :return-object="false" :label="$store.getters.language.data.items.category_name">
                        </v-select>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="items.item_name" type="text" :label="$store.getters.language.data.items.item_name" dense
                            class="mx-1"  filled outlined required >
                        </v-text-field>
                    </v-flex>
                    <!-- <v-flex xs12 lg2 xl2 md3 sm4 v-if="$store.getters.store.store_use_expire_date == 'true'">
                        <v-text-field v-model="items.item_barcode" type="text" :label="$store.getters.language.data.items.item_barcode" dense
                            class="mx-1"  filled outlined  >
                        </v-text-field>
                    </v-flex> -->
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="items.item_sell_price_retail" type="text" :label="$store.getters.language.data.items.item_sell_price_retail" dense
                            class="mx-1"  filled outlined required >
                        </v-text-field>
                    </v-flex>
                    <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="items.item_sell_price_whole" type="text" :label="$store.getters.language.data.items.item_sell_price_whole" dense
                            class="mx-1"  filled outlined required >
                        </v-text-field>
                    </v-flex> -->
                    <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="items.item_sell_price_deposit" type="number" step="any" :label="$store.getters.language.data.items.item_sell_price_deposit" dense
                            class="mx-1" filled outlined v-if="['all', 'qist'].includes($store.getters.store.store_sell_type)">
                        </v-text-field>
                    </v-flex> -->
                    <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="items.item_sell_price_shop" type="text" :label="$store.getters.language.data.items.item_sell_price_shop" dense
                            class="mx-1"  filled outlined required >
                        </v-text-field>
                    </v-flex> -->
                    <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="[{text: $store.getters.language.data.enabled, value: 'enable'}, {text: $store.getters.language.data.disabled, value: 'disable'}]" v-model="items.item_status" dense  filled outlined item-text="text"
                            item-value="value" :return-object="false" :label="$store.getters.language.data.items.item_status">
                        </v-select>
                    </v-flex> -->
<!--             
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="[{text: $store.getters.language.data.yes, value: 'enable'}, {text: $store.getters.language.data.no, value: 'disable'}]" v-model="items.item_btn_show" dense  filled outlined item-text="text"
                            item-value="value" :return-object="false" :label="$store.getters.language.data.items.item_btn_show">
                        </v-select>
                    </v-flex>
             -->
                    <v-flex xs12 lg2 xl2 md3 sm4 v-if="$store.getters.store.store_use_expire_date == 'true'">
                        <v-text-field v-model="items.item_expire_date" type="date" :label="$store.getters.language.data.items.item_expire_date" dense
                            class="mx-1"  filled outlined  >
                        </v-text-field>
                    </v-flex>
                        <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                            <v-select class="mx-1" clearable  :items="[{text: $store.getters.language.data.yes, value: 'true'}, {text: $store.getters.language.data.no, value: 'false'}]" v-model="items.item_available_to_shop" dense  filled outlined item-text="text"
                                item-value="value" :return-object="false" :label="$store.getters.language.data.items.item_available_to_shop">
                            </v-select>
                        </v-flex> -->
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="items.item_available_qty" type="number" :label="$store.getters.language.data.items.item_available_qty" dense
                            class="mx-1"  filled outlined required >
                        </v-text-field>
                    </v-flex>

                        <v-flex xs12 lg2 xl2 md3 sm4 >
                            <v-combobox v-model="items.item_unit" :items="$store.getters.items_list" :return-object=false :label="$store.getters.language.data.items.item_unit" item-text="item_unit" item-value="item_unit" dense
                                class="mx-1"  filled outlined>
                            </v-combobox>
                        </v-flex>

                    <v-flex xs12 lg4 xl4 md4 sm4>
                            <v-textarea rows="1" auto-grow v-model="items.item_description" type="textarea" :label="$store.getters.language.data.items.item_description" dense
                                class="mx-1" filled outlined>
                            </v-textarea>
                        </v-flex>
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn color="success"  type="submit">{{$store.getters.language.data.items.update_btn}}</v-btn>
                    </v-flex>
                    
                </v-layout>
            </form>
        </v-container>
    <v-snackbar v-model="snackbar.value" :color="snackbar.color">
        {{snackbar.text}}
    </v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/items.request.js'
    export default {
        data() {
            return {
                items: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
            }
        },
        computed: {
            categories(){
                return this.$store.getters.categories_list
            },
        },
        mounted(){
            this.id = this.$route.params.id
            if(this.$store.state.store_initialized) {
                this.getOneItems()
            }
        },
        methods: {
            getOneItems() {
                this.loading = true
                requests.getOneItems(this.id).then(r =>{
                    if (r.status == 200) {
                        this.items = r.data.row
                        if(this.$store.getters.store.store_use_expire_date == 'true') {
                            this.items.item_expire_date = r.data.row.item_expire_date ? new Date(r.data.row.item_expire_date).toISOString().split('T')[0] : null
                        }
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: this.$store.getters.language.data.messages.failed,
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            updateItems() {
                this.loading = true
                requests.updateItems(this.id, {
                    item_id: this.items.item_id,
                    category_id: this.items.category_id,
                    item_name: this.items.item_name,
                    item_barcode: this.items.item_barcode,
                    item_sell_price_retail: this.items.item_sell_price_retail,
                    item_sell_price_whole: this.items.item_sell_price_whole,
                    item_sell_price_deposit: this.items.item_sell_price_deposit,
                    item_sell_price_shop: this.items.item_sell_price_shop,
                    item_status: this.items.item_status,
                    item_btn_show: this.items.item_btn_show,
                    item_description: this.items.item_description,
                    item_expire_date: this.items.item_expire_date,
                    item_available_to_shop: this.items.item_available_to_shop,
                    item_available_qty: this.items.item_available_qty,
                    item_unit: this.items.item_unit
                }).then(r => {
                    if (r.status == 200) {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.update_success,
                            color: 'success'
                        }
                        let index = this.$store.getters.items_list.findIndex(obj => obj.item_id == this.id);
                        this.$store.state.items_list[index] = r.data.new_data;
                        this.loading = false
                        this.$router.push('/items-list');
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                        this.loading = false
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: this.$store.getters.language.data.messages.failed,
                        color: 'error'
                    }
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
            },
        },
    }
</script>
                    